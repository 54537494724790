import React from "react";

const CheckboxWithWhiteLabel = ({ label, checked, onChange }) => {
  return (
    <label style={{ color: "white", marginTop: "-5%" }}>
      <input type="checkbox" checked={true} onChange={onChange} />
      {label}
    </label>
  );
};

export default CheckboxWithWhiteLabel;
