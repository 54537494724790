/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import RedButton from "../../components/RedButton";
import api from "../../services/api";

import "react-toastify/dist/ReactToastify.css";
import Dropdown from "../../components/Dropdown";

import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { toast } from "react-toastify";

const MenuDano = () => {
  const navigate = useNavigate();

  const [idModeloEntrada, setIdModeloEntrada] = useState("");
  const [modelosEntrada, setModelosEntrada] = useState([]);
  const [danos, setDanos] = useState([]);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleIdModeloEntrada = (event) => {
    api
      .get(`/damages/aparelho/${event}`)
      .then((response) => {
        setDanos(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    setIdModeloEntrada(event);
  };

  const handleDeleteConfirmation = (item) => {
    setItemToDelete(item);
    setConfirmationDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmationDialogOpen(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = () => {
    api
      .delete(`/damages/${itemToDelete.id}`)
      .then((response) => {
        setDanos(danos.filter((item) => item.id !== itemToDelete.id));
        toast.success("Irregularidade excluída com sucesso!");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });

    setConfirmationDialogOpen(false);
    setItemToDelete(null);
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (!token || role !== "ADMIN") {
      navigate("/login");
    }

    api.get("/inputDevices").then((response) => {
      setModelosEntrada(response.data);
    });
  }, [navigate]);

  return (
    <Container>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleCancelDelete}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
        title="Deseja excluir este parâmetro?"
      />

      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <Title className="text-center">
            <h3>Irregularidades</h3>
          </Title>
          <MDBRow>
            <MDBCol md={12} className="text-start">
              <label>Modelo</label>
              <Dropdown
                options={modelosEntrada}
                onSelect={handleIdModeloEntrada}
              />
            </MDBCol>
          </MDBRow>
          {danos.length > 0 && (
            <MDBTable align="start" style={{ marginTop: "72px" }}>
              <MDBTableHead>
                <tr
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <th>Nome de identificação</th>
                  <th>Valor</th>
                  <th>Ações</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {danos.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    <td>{item.nome}</td>
                    <td>{item.valor}</td>
                    <td>
                      <IconButton
                        onClick={() => navigate(`/editarDano/${item.id}`)}
                      >
                        <Edit style={{ color: "white" }} />
                      </IconButton>

                      <IconButton
                        onClick={() => handleDeleteConfirmation(item)}
                      >
                        <Delete style={{ color: "white" }} />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          )}

          <MDBRow>
            <RedButton onClick={() => navigate("/")}>
              <strong>Voltar</strong>
            </RedButton>
            <RedButton onClick={() => navigate("/cadastroDano")}>
              <strong>Cadastrar</strong>
            </RedButton>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default MenuDano;
