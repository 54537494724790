/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import Input from "../../components/Input";
import RedButton from "../../components/RedButton";
import api from "../../services/api";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";

const CadastroFormaPagamento = () => {
  const navigate = useNavigate();

  const [nomeFormaPagamento, setNomeFormaPagamento] = useState("");
  const [qtdParcelasFormaPagamento, setQtdParcelasFormaPagamento] = useState(0);
  const [taxaFormaPagamento, setTaxaFormaPagamento] = useState(0);

  const handleDescricaoFormaPagamento = (event) => {
    setNomeFormaPagamento(event);
  };

  const handleQtdParcelasFormaPagamento = (event) => {
    setQtdParcelasFormaPagamento(event);
  };

  const handleTaxaFormaPagamento = (event) => {
    setTaxaFormaPagamento(event);
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (!token || role !== "ADMIN") {
      navigate("/login");
    }
  }, [navigate]);

  const formSubmit = (event) => {
    event.preventDefault();

    const qtdParcelasFormaPagamentoFormatado = parseInt(
      qtdParcelasFormaPagamento
    );

    const taxaFormaPagamentoFormatado = parseFloat(
      taxaFormaPagamento.trim().replace(",", ".")
    );

    const data = {
      nome: nomeFormaPagamento,
      qtdParcelas: qtdParcelasFormaPagamentoFormatado,
      taxa: taxaFormaPagamentoFormatado,
    };

    api
      .post("/payments", data)
      .then((response) => {
        toast.success("Forma de pagamento cadastrada com sucesso!");
        navigate("/menuFormaPagamento");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });
  };

  return (
    <Container>
      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <form onSubmit={formSubmit}>
            <Title className="text-center">
              <h3>Formas de pagamento</h3>
            </Title>

            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Descrição</label>
                <Input
                  onChange={(event) => handleDescricaoFormaPagamento(event)}
                  password={false}
                />
              </MDBCol>
              <MDBCol md={3} className="text-start">
                <label>Qtd de parcelas</label>
                <Input
                  onChange={(event) => handleQtdParcelasFormaPagamento(event)}
                  password={false}
                />
              </MDBCol>
              <MDBCol md={3} className="text-start">
                <label>Taxa</label>
                <Input
                  onChange={(event) => handleTaxaFormaPagamento(event)}
                  password={false}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <RedButton onClick={() => navigate("/menuFormaPagamento")}>
                <strong>Voltar</strong>
              </RedButton>
              <RedButton onClick={formSubmit}>
                <strong>Cadastrar</strong>
              </RedButton>
            </MDBRow>
          </form>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default CadastroFormaPagamento;
