import React from "react";
import Button from "@mui/material/Button";

export default function RedButton({ onClick, children }) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        backgroundColor: "#D33E2B",
        color: "white",
        marginTop: "72px",
        marginBottom: "72px",
        width: "40%",
        height: "56px",
        borderRadius: "16px",
        marginLeft: "auto",
        marginRight: "auto",
        "&:hover": {
          backgroundColor: "#8F2A1D", // Mudança de cor quando o mouse está em cima
        },
      }}
    >
      {children}
    </Button>
  );
}
