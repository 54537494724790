import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function CustomTextFieldEdit({ value, onChange, password }) {
  const [showPassword, setShowPassword] = useState(!password);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      value={value || ""} // Garanta que value seja uma string
      onChange={onChange}
      fullWidth
      type={password && showPassword ? "password" : "text"}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "3px",
          backgroundColor: "#36343B",
          textAlign: "left",
          paddingRight: password ? "20px" : "10px",
        },
        "& .MuiInputBase-input": {
          color: "white",
        },
      }}
      InputProps={{
        endAdornment: password && (
          <IconButton onClick={togglePasswordVisibility} edge="end">
            {showPassword ? (
              <VisibilityOff style={{ color: "white" }} />
            ) : (
              <Visibility style={{ color: "white" }} />
            )}
          </IconButton>
        ),
      }}
    />
  );
}
