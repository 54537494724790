import styled from "styled-components";

export const Container = styled.div`
  /* Alinhar horizontalmente na tela */
  display: flex;
  justify-content: center;

  /* Definir altura para o container */
  height: 100vh; /* Isso define a altura do container como 100% da altura da janela */

  /* Centralizar verticalmente */
  align-items: center;

  label {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  img {
    margin-top: 10%;
    margin-bottom: 10%;
  }
`;

export const Title = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 6% 0 1% 0;

  font-weight: 24px;

  h3 {
    font-weight: bold;
    font-weight: 24px;
  }
`;

export const SubmitButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10%;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;
