import styled from "styled-components";

export const Container = styled.div`
  /* Centralizar horizontalmente */
  margin: 0 auto; /* Isso centraliza horizontalmente o contêiner */

  /* Centralizar verticalmente */
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50%; /* Defina a largura como 100% para ocupar todo o espaço disponível */
  min-height: 100vh;

  label {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  img {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Square = styled.div`
  background-color: #36343b;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 6% 0 1% 0;

  font-weight: 24px;

  h3 {
    font-weight: bold;
    font-weight: 24px;
  }
`;

export const SubmitButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10%;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;
