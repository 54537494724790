/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import Input from "../../components/Input";
import RedButton from "../../components/RedButton";
import InputWithMask from "../../components/InputWithMask";
import api from "../../services/api";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "../../components/Dropdown";

import { useNavigate } from "react-router-dom";

const CadastroDano = () => {
  const navigate = useNavigate();

  const [idModeloEntrada, setIdModeloEntrada] = useState("");
  const [nomeDano, setNomeDano] = useState("");
  const [valorDano, setValorDano] = useState(null);

  const [modelos, setModelos] = useState([]);

  const handleValorDano = (event) => {
    setValorDano(event);
  };

  const handleNomeDano = (event) => {
    setNomeDano(event);
  };

  const handleIdModeloEntrada = (event) => {
    console.log(event);
    setIdModeloEntrada(event);
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (!token || role !== "ADMIN") {
      navigate("/login");
    }

    api
      .get("/inputDevices")
      .then((response) => {
        setModelos(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [navigate]);

  const formSubmit = (event) => {
    event.preventDefault();

    const valorDanoFormatado = parseFloat(
      (
        parseFloat(
          valorDano.replace("R$", "").replace(/\./g, "").replace(",", ".")
        ) * 10
      ).toFixed(2)
    );

    const data = {
      nome: nomeDano,
      valor: valorDanoFormatado,
      idAparelhoEntrada: idModeloEntrada,
    };

    api
      .post("/damages", data)
      .then((response) => {
        toast.success("Modelo de compra cadastrado com sucesso!");
        navigate("/menuDano");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });
  };

  return (
    <Container>
      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <form onSubmit={formSubmit}>
            <Title className="text-center">
              <h3>Cadastro de irregularidades</h3>
            </Title>
            <MDBRow>
              <MDBCol md={12} className="text-start">
                <label>Modelo</label>
                <Dropdown options={modelos} onSelect={handleIdModeloEntrada} />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Nome de identificação (ex. Tela quebrada)</label>
                <Input
                  onChange={(event) => handleNomeDano(event)}
                  password={false}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Valor</label>
                <InputWithMask onChange={(event) => handleValorDano(event)} />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <RedButton onClick={() => navigate("/menuDano")}>
                <strong>Voltar</strong>
              </RedButton>
              <RedButton onClick={formSubmit}>
                <strong>Cadastrar</strong>
              </RedButton>
            </MDBRow>
          </form>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default CadastroDano;
