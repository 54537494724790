// multiline.js

import React, { useState } from "react";
import TextField from "@mui/material/TextField";

export default function Multiline({ label, onChange, minRows, maxRows }) {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    onChange(value); // Chama a função de retorno de chamada com o valor inserido
  };

  return (
    <TextField
      id="outlined-basic"
      multiline
      minRows={minRows}
      maxRows={maxRows}
      variant="outlined"
      value={inputValue}
      onChange={handleChange}
      fullWidth
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "3px", // Define o raio da borda
          backgroundColor: "#36343B",
          textAlign: "left",
        },
        "& .MuiInputBase-input": {
          color: "white", // Define a cor do texto como branco
        },
      }}
    />
  );
}
