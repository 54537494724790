import React, { useState } from "react";
import TextField from "@mui/material/TextField";

export default function InputWithMask({ label, onChange }) {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    onChange(value); // Chama a função de retorno de chamada com o valor inserido
  };

  const formatCurrency = (value) => {
    // Verifica se o valor não é vazio
    if (!value) return "";

    // Remove todos os caracteres não numéricos
    const cleanedValue = value.replace(/[^\d]/g, "");
    // Converte o valor para um número
    const numberValue = parseInt(cleanedValue, 10) / 100; // dividido por 100 para considerar os centavos
    // Formata o número como uma moeda brasileira
    const formattedValue = numberValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
    return formattedValue;
  };

  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      value={formatCurrency(inputValue)}
      onChange={handleChange}
      fullWidth
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "3px", // Define o raio da borda
          backgroundColor: "#36343B",
          textAlign: "left",
        },
        "& .MuiInputBase-input": {
          color: "white", // Define a cor do texto como branco
        },
      }}
    />
  );
}
