import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

function ConfirmationDialog({
  open,
  onClose,
  handleCancelDelete,
  handleConfirmDelete,
  title,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          backgroundColor: "#36343B",
          color: "white",
          padding: "32px",
          textAlign: "center",
        }}
      >
        {title || "Excluir parâmetro?"}
      </DialogTitle>
      <DialogActions
        style={{
          backgroundColor: "#36343B",
          color: "white",
          padding: "0 32px 32px 32px",
        }}
      >
        <Button
          onClick={handleCancelDelete}
          style={{ backgroundColor: "#36343B", color: "white" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmDelete}
          style={{ backgroundColor: "#D33E2B", color: "white" }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
