/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import Input from "../../components/Input";
import RedButton from "../../components/RedButton";
import InputWithMask from "../../components/InputWithMask";
import api from "../../services/api";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";

const CadastroModeloEntrada = () => {
  const navigate = useNavigate();

  const [nomeModeloEntrada, setNomeModeloEntrada] = useState("");
  const [valorModeloEntrada, setValorModeloEntrada] = useState("");

  const handleNomeModeloEntrada = (event) => {
    setNomeModeloEntrada(event);
  };

  const handleValorModeloEntrada = (event) => {
    setValorModeloEntrada(event);
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (!token || role !== "ADMIN") {
      navigate("/login");
    }
  }, [navigate]);

  const formSubmit = (event) => {
    event.preventDefault();

    const valorModeloEntradaFormatado = parseFloat(
      (
        parseFloat(
          valorModeloEntrada
            .replace("R$", "")
            .replace(/\./g, "")
            .replace(",", ".")
        ) * 10
      ).toFixed(2)
    );

    const data = {
      nome: nomeModeloEntrada,
      valor: valorModeloEntradaFormatado,
    };

    api
      .post("/inputDevices", data)
      .then((response) => {
        toast.success("Modelo de compra cadastrado com sucesso!");
        navigate("/menuModeloEntrada");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });
  };

  return (
    <Container>
      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <form onSubmit={formSubmit}>
            <Title className="text-center">
              <h3>Cadastro de modelo antigo</h3>
            </Title>
            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Modelo</label>
                <Input
                  onChange={(event) => handleNomeModeloEntrada(event)}
                  password={false}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Valor</label>
                <InputWithMask
                  onChange={(event) => handleValorModeloEntrada(event)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <RedButton onClick={() => navigate("/menuModeloEntrada")}>
                <strong>Voltar</strong>
              </RedButton>
              <RedButton onClick={formSubmit}>
                <strong>Cadastrar</strong>
              </RedButton>
            </MDBRow>
          </form>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default CadastroModeloEntrada;
