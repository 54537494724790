import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import InputEdit from "../../components/InputEdit";
import RedButton from "../../components/RedButton";
import api from "../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams } from "react-router-dom";

const EditarModeloEntrada = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [nomeModeloEntrada, setNomeModeloEntrada] = useState("");
  const [valorModeloEntrada, setValorModeloEntrada] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (!token || role !== "ADMIN") {
      navigate("/login");
    }

    api.get(`/inputDevices/${id}`).then((response) => {
      if (response.data) {
        setNomeModeloEntrada(response.data.nome || "");
        setValorModeloEntrada(response.data.valor || "0");
      }
    });
  }, [navigate, id]);

  const handleDescricaoModeloEntrada = (event) => {
    setNomeModeloEntrada(event.target.value);
  };

  const handleValorModeloEntrada = (event) => {
    setValorModeloEntrada(event.target.value);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    const valorModeloEntradaFormatado = parseFloat(
      valorModeloEntrada.replace(",", ".")
    );

    const data = {
      nome: nomeModeloEntrada,
      valor: valorModeloEntradaFormatado,
    };

    api
      .put(`/inputDevices/${id}`, data)
      .then((response) => {
        toast.success("Modelo de entrada alterado com sucesso!");
        navigate("/menuModeloEntrada");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });
  };

  return (
    <Container>
      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <form onSubmit={formSubmit}>
            <Title className="text-center">
              <h3>Editar {nomeModeloEntrada}</h3>
            </Title>

            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Nome</label>
                <InputEdit
                  value={nomeModeloEntrada}
                  onChange={(event) => handleDescricaoModeloEntrada(event)}
                  password={false}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Valor</label>
                <InputEdit
                  value={valorModeloEntrada}
                  onChange={(event) => handleValorModeloEntrada(event)}
                  password={false}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <RedButton onClick={() => navigate("/menuModeloEntrada")}>
                <strong>Voltar</strong>
              </RedButton>
              <RedButton onClick={formSubmit}>
                <strong>confirmar edição</strong>
              </RedButton>
            </MDBRow>
          </form>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default EditarModeloEntrada;
