import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { IconButton } from "@mui/material";

import { Edit, Delete } from "@mui/icons-material";
import api from "../../services/api";
import RedButton from "../../components/RedButton";

import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { toast } from "react-toastify";

const MenuModeloSaida = () => {
  const navigate = useNavigate();

  const [modelosSaida, setModelosSaida] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (!token || role !== "ADMIN") {
      navigate("/login");
    }

    api.get("/outputDevices").then((response) => {
      setModelosSaida(response.data);
    });
  }, [navigate]);

  const handleDeleteConfirmation = (item) => {
    setItemToDelete(item);
    setConfirmationDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmationDialogOpen(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = () => {
    api
      .delete(`/outputDevices/${itemToDelete.id}`)
      .then((response) => {
        setModelosSaida(
          modelosSaida.filter((item) => item.id !== itemToDelete.id)
        );
        toast.success("Modelo de venda excluído com sucesso!");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });

    setConfirmationDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <Container>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleCancelDelete}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
        title="Deseja excluir este parâmetro?"
      />

      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <Title className="text-center">
            <h3>Modelos de Venda</h3>
          </Title>

          <MDBTable align="start" style={{ marginTop: "72px" }}>
            <MDBTableHead>
              <tr
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {modelosSaida.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <td>{item.nome}</td>
                  <td>
                    <IconButton
                      onClick={() => navigate(`/editarModeloSaida/${item.id}`)}
                    >
                      <Edit style={{ color: "white" }} />
                    </IconButton>

                    <IconButton onClick={() => handleDeleteConfirmation(item)}>
                      <Delete style={{ color: "white" }} />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>

          <MDBRow>
            <RedButton onClick={() => navigate("/")}>
              <strong>Voltar</strong>
            </RedButton>
            <RedButton onClick={() => navigate("/cadastroModeloSaida")}>
              <strong>Cadastrar</strong>
            </RedButton>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default MenuModeloSaida;
