import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { IconButton } from "@mui/material";

import { Delete, Check } from "@mui/icons-material";
import api from "../../services/api";
import RedButton from "../../components/RedButton";

import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { toast } from "react-toastify";

const MenuVendedor = () => {
  const navigate = useNavigate();

  const [vendedores, setVendedores] = useState([]);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [confirmationApproveDialogOpen, setConfirmationApproveDialogOpen] =
    useState(false);
  const [itemToApprove, setItemToApprove] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    if (!token || role !== "ADMIN") {
      navigate("/login");
    }

    api.get("/employees").then((response) => {
      setVendedores(response.data);
    });
  }, [navigate]);

  const handleDeleteConfirmation = (item) => {
    setItemToDelete(item);
    setConfirmationDialogOpen(true);
  };

  const handleApproveConfirmation = (item) => {
    setItemToApprove(item);
    setConfirmationApproveDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmationDialogOpen(false);
    setItemToDelete(null);
  };

  const handleCancelApprove = () => {
    setConfirmationApproveDialogOpen(false);
    setItemToApprove(null);
  };

  const handleConfirmApprove = () => {
    console.log(itemToApprove.id);

    api
      .put(`/employees/${itemToApprove.id}`)
      .then((response) => {
        setVendedores(
          vendedores.map((item) =>
            item.id === itemToApprove.id ? { ...item, status: "OK" } : item
          )
        );
        toast.success("Vendedor aprovado com sucesso!");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });

    setConfirmationApproveDialogOpen(false);
    setItemToApprove(null);
  };

  const handleConfirmDelete = () => {
    console.log(itemToDelete.id);

    api
      .delete(`/employees/${itemToDelete.id}`)
      .then((response) => {
        setVendedores(vendedores.filter((item) => item.id !== itemToDelete.id));
        toast.success("Vendedor excluído com sucesso!");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });

    setConfirmationDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <Container>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleCancelDelete}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
        title="Deseja excluir este parâmetro?"
      />

      <ConfirmationDialog
        open={confirmationApproveDialogOpen}
        onClose={handleCancelApprove}
        handleCancelDelete={handleCancelApprove}
        handleConfirmDelete={handleConfirmApprove}
        title="Deseja aprovar o acesso?"
      />

      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <Title className="text-center">
            <h3>Vendedores</h3>
          </Title>

          <MDBTable align="start" style={{ marginTop: "72px" }}>
            <MDBTableHead>
              <tr
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {vendedores.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <td>{item.nome}</td>
                  <td>
                    {/*<IconButton
                      onClick={() => navigate(`/editarVendedor/${item.id}`)}
                    >
                      <Edit style={{ color: "white" }} />
                </IconButton>*/}

                    {item.status === "PENDING" && (
                      <IconButton
                        onClick={() => handleApproveConfirmation(item)}
                      >
                        <Check style={{ color: "white" }} />
                      </IconButton>
                    )}

                    <IconButton onClick={() => handleDeleteConfirmation(item)}>
                      <Delete style={{ color: "white" }} />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>

          <MDBRow>
            <RedButton onClick={() => navigate("/")}>
              <strong>Voltar</strong>
            </RedButton>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default MenuVendedor;
