import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#36343B", // Cor de fundo das opções
      color: "white", // Cor do texto das opções
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName === name
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

export default function Dropdown({ options, label, onSelect }) {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelect(value); // Chama a função de retorno de chamada com o valor selecionado
  };

  return (
    <div>
      <FormControl
        sx={{
          width: "100%",
        }}
      >
        <Select
          id="demo-single-name"
          value={selectedValue}
          onChange={handleChange}
          MenuProps={MenuProps}
          style={{ backgroundColor: "#36343B", textAlign: "left" }}
          IconComponent={KeyboardArrowDownOutlinedIcon}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "3px", // Define o raio da borda
              backgroundColor: "#36343B",
              textAlign: "left",
            },
            "& .MuiInputBase-input": {
              color: "white", // Define a cor do texto como branco
            },
            "& .MuiMenuItem-root": {
              color: "white", // Define a cor do texto das opções como branco
            },
          }}
        >
          {options.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={{
                ...getStyles(item.id, selectedValue, theme),
              }}
            >
              {item.nome}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
