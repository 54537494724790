import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function CustomTextField({ label, onChange, password }) {
  const [inputValue, setInputValue] = useState("");
  const [showPassword, setShowPassword] = useState(!password);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    onChange(value); // Chama a função de retorno de chamada com o valor inserido
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      value={inputValue}
      onChange={handleChange}
      fullWidth
      type={showPassword ? "text" : "password"}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "3px", // Define o raio da borda
          backgroundColor: "#36343B",
          textAlign: "left",
          paddingRight: password ? "20px" : "10px", // Adiciona espaço à direita para o ícone
        },
        "& .MuiInputBase-input": {
          color: "white", // Define a cor do texto como branco
        },
      }}
      InputProps={{
        endAdornment: password && (
          <IconButton onClick={togglePasswordVisibility} edge="end">
            {showPassword ? (
              <VisibilityOff style={{ color: "white" }} />
            ) : (
              <Visibility style={{ color: "white" }} />
            )}
          </IconButton>
        ),
      }}
    />
  );
}
