import styled from "styled-components";

export const Container = styled.div`
  /* Centralizar horizontalmente */
  margin: 0 auto; /* Isso centraliza horizontalmente o contêiner */

  /* Centralizar verticalmente */
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%; /* Defina a largura como 100% para ocupar todo o espaço disponível */
  min-height: 100vh;
  overflow-y: auto;

  label {
    margin-top: 30px;
    margin-bottom: 4px;
  }
`;

export const Title = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 36px 0 2px 0;

  h3 {
    font-weight: bold;
    font-weight: 24px;
  }
`;

export const SubmitButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10%;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;
