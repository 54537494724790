import { useState } from "react";
import api from "../services/api";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const login = async (email, senha) => {
    try {
      const response = await api.post("/login", { email, senha });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("email", response.data.email);
      localStorage.setItem("idFuncionario", response.data.id);
      localStorage.setItem("role", response.data.role);
      setAuthenticated(true);
      return response.data.role;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("idFuncionario");
    setAuthenticated(false);
    navigate("/login");
  };

  const isAuthenticated = () => {
    const role = localStorage.getItem("role");

    const isAdmin = role === "ADMIN";
    return isAdmin;
  };

  return { authenticated, login, logout, isAuthenticated };
};

export default useAuth;
