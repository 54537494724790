/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import InputWithMaskBlocked from "../../components/InputWithMaskBlocked";
import InputBlocked from "../../components/InputBlocked";
import Input from "../../components/Input";
import RedButton from "../../components/RedButton";
import CheckboxLabels from "../../components/Checkbox";
import { useParams } from "react-router-dom";
import api from "../../services/api";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiline from "../../components/Multiline";

import { useNavigate } from "react-router-dom";

const CadastroVendedor = () => {
  const navigate = useNavigate();

  const [nomeVendedor, setNomeVendedor] = useState("");
  const [emailVendedor, setEmailVendedor] = useState("");
  const [senhaVendedor, setSenhaVendedor] = useState("");
  const [confirmeSenhaVendedor, setConfirmeSenhaVendedor] = useState("");

  const handleNomeVendedor = (event) => {
    setNomeVendedor(event);
  };

  const handleEmailVendedor = (event) => {
    setEmailVendedor(event);
  };

  const handleSenhaVendedor = (event) => {
    setSenhaVendedor(event);
  };

  const handleConfirmeSenhaVendedor = (event) => {
    setConfirmeSenhaVendedor(event);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (senhaVendedor !== confirmeSenhaVendedor) {
      toast.warn("As senhas não coincidem");
      return;
    }

    if (
      !nomeVendedor ||
      !emailVendedor ||
      !senhaVendedor ||
      !confirmeSenhaVendedor
    ) {
      toast.warn("Preencha todos os campos");
      return;
    }

    // Ver se email é email mesmo

    if (!emailVendedor.includes("@") || !emailVendedor.includes(".")) {
      toast.warn("Email inválido");
      return;
    }

    const data = {
      nome: nomeVendedor,
      email: emailVendedor,
      senha: senhaVendedor,
    };

    api
      .post("/employees", data)
      .then((response) => {
        toast.success("Vendedor cadastrado com sucesso!");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Erro: ", error);
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            toast.warning(`${error.response.data.error}`);
          } else {
            toast.error(`${error.response.data.error}`);
          }
        } else {
          toast.error("Erro interno de servidor. Por favor, tente novamente!");
        }
      });
  };

  return (
    <Container>
      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <form onSubmit={formSubmit}>
            <Title className="text-center">
              <h3>Cadastro</h3>
            </Title>
            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Nome</label>
                <Input
                  onChange={(event) => handleNomeVendedor(event)}
                  password={false}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Email</label>
                <Input
                  onChange={(event) => handleEmailVendedor(event)}
                  password={false}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Senha</label>
                <Input
                  onChange={(event) => handleSenhaVendedor(event)}
                  password={true}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Confirme a senha</label>
                <Input
                  onChange={(event) => handleConfirmeSenhaVendedor(event)}
                  password={true}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <RedButton onClick={() => navigate("/login")}>
                <strong>Voltar</strong>
              </RedButton>
              <RedButton onClick={formSubmit}>
                <strong>Cadastrar</strong>
              </RedButton>
            </MDBRow>
          </form>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default CadastroVendedor;
