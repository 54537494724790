import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

export default function InputWithMaskBlocked({
  label,
  onChange,
  defaultValue,
}) {
  const [inputValue, setInputValue] = useState(defaultValue || "");

  useEffect(() => {
    setInputValue(defaultValue || "");
  }, [defaultValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    onChange(value);
  };

  const formatCurrency = (value) => {
    if (typeof value !== "number") return ""; // Se não for um número, retorna uma string vazia

    // Formata o número como moeda brasileira
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
    return formattedValue;
  };

  return (
    <div style={{ position: "relative" }}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        readOnly={true}
        value={formatCurrency(inputValue)}
        onChange={handleChange}
        fullWidth
        inputProps={{ readOnly: true }} // torna o campo de entrada somente leitura
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "3px", // Define o raio da borda
            backgroundColor: "#36343B",
            textAlign: "left",
            pointerEvents: "none", // desativa eventos de mouse no campo de entrada
          },
          "& .MuiInputBase-input": {
            color: "white", // Define a cor do texto como branco
          },
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          pointerEvents: "auto", // reativa eventos de mouse nesta camada
        }}
      />
    </div>
  );
}
