/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import api from "../../services/api";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import Dropdown from "../../components/Dropdown";
import Input from "../../components/Input";
import Multiline from "../../components/Multiline";
import InputWithMask from "../../components/InputWithMask";
import InputWithMaskBlocked from "../../components/InputWithMaskBlocked";
import InputBlocked from "../../components/InputBlocked";
import RedButton from "../../components/RedButton";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

const Orcamento = () => {
  const navigate = useNavigate();

  const [modeloSaida, setModeloSaida] = useState(null);
  const [valorModeloSaida, setValorModeloSaida] = useState("");

  const [idModeloEntrada, setIdModeloEntrada] = useState(null);
  const [nomeModeloEntrada, setNomeModeloEntrada] = useState(null);
  const [valorNomeModeloEntrada, setValorNomeModeloEntrada] = useState("");

  const [entradaPamento, setEntradaPamento] = useState(null);
  const [valorEntrada, setValorEntrada] = useState("");

  const [danos, setDanos] = useState([]);
  const [nomesDanos, setNomesDanos] = useState([]);

  const [modelosSaida, setModelosSaida] = useState([]);
  const [modelosEntrada, setModelosEntrada] = useState([]);

  const [danosSelecionados, setDanosSelecionados] = useState({});

  const [observacoes, setObservacoes] = useState("");
  const [valorObservacoes, setValorObservacoes] = useState(null);

  const options = [
    { id: "sim", nome: "Sim" },
    { id: "nao", nome: "Não" },
  ];

  const formSubmit = async (event) => {
    try {
      const valorModeloSaidaFormatado = parseFloat(
        (
          parseFloat(
            valorModeloSaida
              .replace("R$", "")
              .replace(/\./g, "")
              .replace(",", ".")
          ) * 10
        ).toFixed(2)
      );

      const valorEntradaFormatado = parseFloat(
        (
          parseFloat(
            valorEntrada.replace("R$", "").replace(/\./g, "").replace(",", ".")
          ) * 10
        ).toFixed(2)
      );

      let valorObservacoesFormatado = valorObservacoes;

      if (!modeloSaida) {
        toast.warn("Selecione um modelo de saída");
        return;
      }

      if (!valorModeloSaida) {
        toast.warn("Preencha o valor do modelo de saída");
        return;
      }
      if (!idModeloEntrada) {
        toast.warn("Selecione um modelo de entrada");
        return;
      }

      const data = {
        idFuncionario: localStorage.getItem("idFuncionario"),
        idAparelhoSaida: modeloSaida,
        idAparelhoEntrada: idModeloEntrada,
        valorAparelhoSaida: valorModeloSaidaFormatado,
        idsDanos: Object.keys(danosSelecionados),
        observacoes: observacoes,
        valorObservacoes: valorObservacoesFormatado,
        valorEntrada: valorEntradaFormatado,
      };

      await api
        .post(`/budgets`, data)
        .then((response) => {
          response.data = {
            ...response.data,
            observacoes: observacoes,
          };

          const jsonParam = encodeURIComponent(JSON.stringify(response.data));
          navigate(`/print/${jsonParam}`);
        })
        .catch((error) => {
          console.error("Erro: ", error);
          if (error.response) {
            if (
              error.response.status === 404 ||
              error.response.status === 400
            ) {
              toast.warning(`${error.response.data.error}`);
            } else {
              toast.error(`${error.response.data.error}`);
            }
          } else {
            toast.error(
              "Erro interno de servidor. Por favor, tente novamente!"
            );
          }
        });
    } catch (err) {
      if (err instanceof Error) {
        console.log("err.message", err.message);
      }
    }
  };

  const handleModeloSaida = (event) => {
    setModeloSaida(event);
  };

  const handleValorModeloSaida = (event) => {
    setValorModeloSaida(event);
  };

  const handleValorOutroSelecionado = (event) => {
    setValorObservacoes(
      parseFloat(event.replace("R$", "").trim().replace(",", ".")) * 10
    );
  };

  const handleOutroSelecionado = (event) => {};

  const handleNomeModeloEntradaSelect = (modeloSelecionado) => {
    const modelo = modelosEntrada.find(
      (modelo) => modelo.id === modeloSelecionado
    );

    api
      .get(`/damages`)
      .then((response) => {
        const danos = response.data.filter(
          (dano) => dano.idAparelhoEntrada === modelo.id
        );

        setDanos(danos);
      })
      .catch((error) => {
        console.log("error", error);
      });

    setIdModeloEntrada(modelo.id);
    setNomeModeloEntrada(modelo.nome);
    setValorNomeModeloEntrada(modelo.valor);

    setDanosSelecionados({});

    setValorEntrada("");
  };

  const handleEntradaPagamentoSelect = (event) => {
    setEntradaPamento(event);
  };

  const handleValorEntradaPagamentoSelect = (event) => {
    setValorEntrada(event);
  };

  const handleObservacoes = (event) => {
    setObservacoes(event);
  };

  const handleDanoSelect = (event, danoSelecionado) => {
    const infos = danos.find((dano) => dano.id === danoSelecionado.id);

    const valor = event === "sim" ? infos.valor : "";

    if (event === "sim") {
      setDanosSelecionados((prevState) => ({
        ...prevState,
        [danoSelecionado.id]: valor,
      }));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (!token || !role) {
      navigate("/login");
    }

    api
      .get(`/outputDevices`)
      .then((response) => {
        setModelosSaida(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });

    api
      .get(`/inputDevices`)
      .then((response) => {
        setModelosEntrada(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [navigate]);

  return (
    <Container>
      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <form onSubmit={formSubmit}>
            <Title className="text-center">
              <h3>Modelo novo</h3>
            </Title>
            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Modelo</label>
                <Dropdown
                  options={modelosSaida}
                  onSelect={(event) => handleModeloSaida(event)}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Valor</label>
                <InputWithMask
                  onChange={(event) => handleValorModeloSaida(event)}
                />
              </MDBCol>
            </MDBRow>

            <Title className="text-center">
              <h3>Modelo antigo</h3>
            </Title>
            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Modelo</label>
                <Dropdown
                  options={modelosEntrada}
                  onSelect={handleNomeModeloEntradaSelect}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Valor</label>
                <InputWithMaskBlocked
                  label="Valor"
                  defaultValue={valorNomeModeloEntrada}
                />
              </MDBCol>
            </MDBRow>

            {danos.map((dano) => (
              <div key={dano.id}>
                <MDBRow>
                  <MDBCol md={6} className="text-start">
                    <label>{dano.nome}?</label>
                    <Dropdown
                      options={options}
                      onSelect={(event) => handleDanoSelect(event, dano)}
                    />
                  </MDBCol>
                  <MDBCol md={6} className="text-start">
                    <label>Valor</label>
                    <InputWithMaskBlocked
                      label="Valor"
                      defaultValue={danosSelecionados[dano.id] || 0}
                    />
                  </MDBCol>
                </MDBRow>
              </div>
            ))}

            {danos.length > 0 && (
              <>
                <MDBRow>
                  <MDBCol md={6} className="text-start">
                    <label>Outro valor?</label>
                    <Dropdown
                      options={options}
                      onSelect={(event) => handleOutroSelecionado(event)}
                    />
                  </MDBCol>
                  <MDBCol md={6} className="text-start">
                    <label>Valor</label>
                    <InputWithMask
                      label="Valor"
                      onChange={(event) => handleValorOutroSelecionado(event)}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md={12} className="text-start">
                    <label>Observações</label>
                    <Multiline
                      minRows={4}
                      maxRows={8}
                      onChange={(event) => handleObservacoes(event)}
                    />
                  </MDBCol>
                </MDBRow>
              </>
            )}

            <Title className="text-center">
              <h3>Pagamento</h3>
            </Title>
            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Entrada?</label>
                <Dropdown
                  options={options}
                  onSelect={(event) => handleEntradaPagamentoSelect(event)}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Valor</label>
                <InputWithMask
                  onChange={(event) => handleValorEntradaPagamentoSelect(event)}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <RedButton onClick={formSubmit}>
                <strong>Ver resultado</strong>
              </RedButton>
            </MDBRow>
          </form>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default Orcamento;
