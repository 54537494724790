// Routes.js
import { Route, Routes as RoutesDom } from "react-router-dom";

import Orcamento from "../pages/Orcamento/Orcamento";
import Print from "../pages/Print/Print";
import CadastroModeloSaida from "../pages/CadastroModeloSaida/CadastroModeloSaida";
import CadastroModeloEntrada from "../pages/CadastroModeloEntrada/CadastroModeloEntrada";
import CadastroVendedor from "../pages/CadastroVendedor/CadastroVendedor";
import CadastroDano from "../pages/CadastroDano/CadastroDano";
import Menu from "../pages/Menu/Menu";
import Login from "../pages/Login/Login";
import CadastroFormaPagamento from "../pages/CadastroFormaPagamento/CadastroFormaPagamento";
import EditarFormaPagamento from "../pages/EditarFormaPagamento/EditarFormaPagamento";
import EditarVendedor from "../pages/EditarVendedor/EditarVendedor";
import EditarModeloSaida from "../pages/EditarModeloSaida/EditarModeloSaida";
import EditarModeloEntrada from "../pages/EditarModeloEntrada/EditarModeloEntrada";
import EditarDano from "../pages/EditarDano/EditarDano";
import MenuFormaPagamento from "../pages/MenuFormaPagamento/MenuFormaPagamento";
import MenuVendedor from "../pages/MenuVendedor/MenuVendedor";
import MenuModeloSaida from "../pages/MenuModeloSaida/MenuModeloSaida";
import MenuModeloEntrada from "../pages/MenuModeloEntrada/MenuModeloEntrada";
import MenuDano from "../pages/MenuDano/MenuDano";

const Routes = () => {
  return (
    <RoutesDom>
      <Route path="/" element={<Menu />} />
      <Route path="/login" element={<Login />} />
      <Route path="/orcamento" element={<Orcamento />} />
      <Route path="/print/:json" element={<Print />} />
      <Route path="/cadastroModeloSaida" element={<CadastroModeloSaida />} />
      <Route path="/menuModeloSaida" element={<MenuModeloSaida />} />
      <Route path="/editarModeloSaida/:id" element={<EditarModeloSaida />} />
      <Route
        path="/editarModeloEntrada/:id"
        element={<EditarModeloEntrada />}
      />
      <Route path="/menuDano" element={<MenuDano />} />
      <Route path="/editarDano/:id" element={<EditarDano />} />
      <Route path="/menuModeloEntrada" element={<MenuModeloEntrada />} />
      <Route
        path="/cadastroModeloEntrada"
        element={<CadastroModeloEntrada />}
      />
      <Route path="/cadastroVendedor" element={<CadastroVendedor />} />
      <Route path="/cadastroDano" element={<CadastroDano />} />
      <Route
        path="/cadastroFormaPagamento"
        element={<CadastroFormaPagamento />}
      />
      <Route
        path="/editarFormaPagamento/:id"
        element={<EditarFormaPagamento />}
      />
      <Route path="/menuFormaPagamento" element={<MenuFormaPagamento />} />
      <Route path="/menuVendedor" element={<MenuVendedor />} />
      <Route path="/editarVendedor/:id" element={<EditarVendedor />} />
    </RoutesDom>
  );
};

export default Routes;
