/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Title } from "./styles";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import InputWithMaskBlocked from "../../components/InputWithMaskBlocked";
import InputBlocked from "../../components/InputBlocked";
import CheckboxLabels from "../../components/Checkbox";
import { useParams, useNavigate } from "react-router-dom";
import RedButton from "../../components/RedButton";
import moment from "moment";
import "moment-timezone"; // Importe o Moment Timezone

const Print = (props) => {
  const parametros = useParams(); // Pega os parâmetros da URL
  const navigate = useNavigate();

  const parsedData = JSON.parse(parametros.json);

  console.log(parsedData);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (!token || !role) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Container>
      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <Title className="text-center">
            <h3>
              Orçamento <br /> More Mega Store
            </h3>
          </Title>
          <MDBRow>
            <MDBCol md={12} className="text-start">
              <label>Vendedor</label>
              <InputBlocked
                label="Valor"
                defaultValue={parsedData.nomeFuncionario}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md={6} className="text-start">
              <label>Modelo novo</label>
              <InputBlocked
                label="Valor"
                defaultValue={parsedData.nomeAparelhoSaida}
              />
            </MDBCol>
            <MDBCol md={6} className="text-start">
              <label>Modelo antigo</label>
              <InputBlocked
                label="Valor"
                defaultValue={parsedData.nomeAparelhoEntrada}
              />
            </MDBCol>
          </MDBRow>

          {parsedData.observacoes && (
            <MDBRow>
              <MDBCol md={12} className="text-start">
                <label>Observações</label>
                <InputBlocked
                  label="Valor"
                  defaultValue={parsedData.observacoes}
                />
              </MDBCol>
            </MDBRow>
          )}

          {parsedData.nomesDanos && parsedData.nomesDanos[0] !== null && (
            <MDBRow className="text-start">
              <label>Danos:</label>
              {parsedData.nomesDanos.map((dano) => (
                <MDBCol md={3} className="text-start">
                  <CheckboxLabels label={` ${dano}`} />
                </MDBCol>
              ))}
            </MDBRow>
          )}
          <MDBRow>
            {parsedData.valorEntrada && (
              <MDBCol md={6} className="text-start">
                <label>Valor de Entrada</label>
                <InputWithMaskBlocked
                  label="Valor"
                  defaultValue={parsedData.valorEntrada}
                />
              </MDBCol>
            )}

            {parsedData.valorEntrada
              ? parsedData.valorDiferenca.map((formaPagamento) => (
                  <MDBCol md={6} className="text-start">
                    <label>
                      Pagamento {formaPagamento.nome} + R${" "}
                      {parsedData.valorEntrada} de Entrada
                    </label>
                    <InputWithMaskBlocked
                      label="Valor"
                      defaultValue={parseFloat(formaPagamento.valor)}
                    />
                  </MDBCol>
                ))
              : parsedData.valorDiferenca.map((formaPagamento) => (
                  <MDBCol md={6} className="text-start">
                    <label>Pagamento {formaPagamento.nome}</label>
                    <InputWithMaskBlocked
                      label="Valor"
                      defaultValue={parseFloat(formaPagamento.valor)}
                    />
                  </MDBCol>
                ))}
          </MDBRow>
          <MDBRow>
            <label>
              Orçamento relizado em{" "}
              {moment(parsedData.createdAt).format("DD/MM/YYYY")} às{" "}
              {moment(parsedData.createdAt).format(" HH:mm")}
            </label>
          </MDBRow>

          <img
            src="../../logo.svg"
            alt=""
            height="47"
            width="146"
            style={{ marginTop: "48px", marginBottom: "24px" }}
          />
          <MDBRow>
            <RedButton onClick={() => navigate("/orcamento")}>
              <strong>Novo orçamento</strong>
            </RedButton>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default Print;
