/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Square, Title } from "./styles";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import InputWithMaskBlocked from "../../components/InputWithMaskBlocked";
import InputBlocked from "../../components/InputBlocked";
import Input from "../../components/Input";
import RedButton from "../../components/RedButtonMenu";
import CheckboxLabels from "../../components/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiline from "../../components/Multiline";

import auth from "../../hooks/auth";

const Menu = () => {
  const navigate = new useNavigate();

  const { logout } = auth();

  const menu = [
    {
      title: "Orçamento",
      path: "/orcamento",
    },
    {
      title: "Irregularidades",
      path: "/menuDano",
    },
    {
      title: "Modelos de venda",
      path: "/menuModeloSaida",
    },

    {
      title: "Modelos de entrada",
      path: "/menuModeloEntrada",
    },
    {
      title: "Vendedores",
      path: "/menuVendedor",
    },
    {
      title: "Formas de pagamento",
      path: "/menuFormaPagamento",
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (!token || role !== "ADMIN") {
      navigate("/login");
    }
  });

  return (
    <Container>
      <MDBRow>
        {menu.map((item, index) => (
          <MDBCol
            key={index}
            xg={6}
            lg={6}
            md={8}
            sm={12}
            //className={index === 0 ? "offset-xg-3 offset-lg-3 offset-md-3" : ""}
          >
            <Square>
              <Title>
                <h3>{item.title}</h3>
              </Title>
              <RedButton onClick={() => navigate(item.path)}>
                Selecionar
              </RedButton>
            </Square>
          </MDBCol>
        ))}
        <RedButton onClick={() => logout()}>
          <strong>Deslogar</strong>
        </RedButton>
      </MDBRow>
    </Container>
  );
};

export default Menu;
