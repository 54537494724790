// Login.js

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Container } from "./styles";
import Input from "../../components/Input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuth from "../../hooks/auth";
import RedButton from "../../components/RedButton";

const Login = () => {
  const { login } = useAuth();
  const [senha, setSenha] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleEmail = (event) => {
    setEmail(event);
  };

  const handleSenha = (event) => {
    setSenha(event);
  };

  const formSubmit = async (event) => {
    event.preventDefault();

    try {
      const responseLogin = await login(email, senha); // chama a função login do hook useAuth
      if (responseLogin) {
        if (responseLogin === "ADMIN") {
          navigate("/");
        } else {
          navigate("/orcamento");
        }
      } else {
        toast.error("Senha incorreta");
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log("err.message", err.message);
      }
    }
  };

  return (
    <Container>
      <MDBRow style={{ width: "100%", justifyContent: "center" }}>
        <MDBCol xg={6} lg={6} md={8} sm={12}>
          <form onSubmit={formSubmit}>
            <img
              src="../../logo.svg"
              alt=""
              height="47"
              width="146"
              style={{ marginTop: "48px", marginBottom: "24px" }}
            />
            <MDBRow>
              <MDBCol md={6} className="text-start">
                <label>Email</label>
                <Input
                  onChange={(event) => handleEmail(event)}
                  password={false}
                />
              </MDBCol>
              <MDBCol md={6} className="text-start">
                <label>Senha</label>
                <Input
                  onChange={(event) => handleSenha(event)}
                  password={true}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="text-start" style={{ marginBottom: "-5%" }}>
              <label
                onClick={() => navigate("/cadastroVendedor")}
                style={{ cursor: "pointer" }}
              >
                Ainda não tem cadastro? Clique aqui!
              </label>
            </MDBRow>

            <MDBRow>
              <RedButton onClick={formSubmit}>
                <strong>Entrar</strong>
              </RedButton>
            </MDBRow>
          </form>
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default Login;
